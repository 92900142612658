@import url('https://fonts.googleapis.com/css2?family=Barlow&family=Roboto&display=swap');

// Application Font Family
* {
  font-family: 'Barlow', sans-serif !important;
}

// remove the blue border around a selected cell
// at the moment there is no way to disable this via DataGrid props
.MuiDataGrid-root {

  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within,
  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }
}

.MuiTableCell-body.preview-value {
  font-weight: 500;
}

// keep font size on buttons no matter the screen size
.buttonAlwaysText {
  font-size: 0.9rem !important;

  .MuiButton-startIcon {
    margin-right: 8px !important;
    margin-left: -4px !important;
  }
}

//TUTORIAL Reactour
// css applied for user tooltip to be moved more to the left
.reactour__helper {
  max-width: none !important;
  width: calc(100% - 350px);
}

// Payment Plan Ribbon
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: 1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

// Large Icons on mobile && spacing in table cells
@media only screen and (max-width: 550px) {
  .MuiSvgIcon-root {
    font-size: 24px !important;
  }

  .MuiTableCell-root {
    min-width: 140px;
  }
}

// Datepicker Range Color Change

.rdrDayToday .rdrDayNumber span:after {
  background: #33AD93;
}

.rdrStartEdge {
  color: #33AD93 !important;
}

.rdrInRange {
  color: #33AD93 !important;
}

.rdrEndEdge {
  color: #33AD93 !important;
}

.rdrDateDisplayItemActive {
  border-color: #33AD93
}

.rdrDayInPreview {
  border-color: #33AD93
}

// Remove MUI Popover warning
.MuiDialogActions-root>:not(:first-of-type) {
  margin-left: 0 !important;
}

// Live Icon from Offline Locations

.live-record {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(209, 117, 117, 1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(209, 117, 117, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(209, 117, 117, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(209, 117, 117, 0);
  }
}

// Datepicker Responsive

@media only screen and (max-width: 900px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column-reverse;
  }

  .rdrMonths {
    flex-direction: column;
  }

  .rdrDefinedRangesWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Keeping Roboto Font on pre html elements

pre {
  font-family: 'Roboto', sans-serif !important;
}

.suspended-bar {
  background-color: #b75050;
  text-align: center;
  padding: 8px 0;
  font-size: 12px;
  color: #FFF;
  height: 35px;
}